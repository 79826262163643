import './Header.css';
import Home from './home/Home';
import './App.css';
import License from './license/license';
import Paintings from './paintings/Paintings';
import { useState } from 'react';

function App() {

    const [selected_tab, setSelectedTab] = useState('Paintings');

    return (
        <div className="App">
            <div className='Header'>
            
                <div className='HeaderItem' onClick={()=>{setSelectedTab("Home")}}>
                <span>Home</span>
                </div>

                <div className='HeaderItem' onClick={()=>{setSelectedTab("XR & Game")}}>
                <span>XR & Game</span>
                </div>
                
                <div className='HeaderItem' onClick={()=>{setSelectedTab("Web")}}>
                <span>Web</span>
                </div>

                <div className='HeaderItem' onClick={()=>{setSelectedTab("Paintings")}}>
                <span>Paintings</span>
                </div>

            </div>


            {
                selected_tab === 'Home' ? ( <Home/>) : 
                //selected_tab === 'XR & Game' ? ( <XRGame/>) :
                //selected_tab === 'Web' ? ( <Web/>) :
                selected_tab === 'Paintings' ? ( <Paintings/>) :
                null
            }

            {navigator.language.startsWith('zh')?(
                <License/>
            ):null}
        </div>
    );
}

export default App;
