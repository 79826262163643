import React, { useState, useEffect } from 'react';
import './Paintings.css'; // 确保你的CSS文件已经引入

import { painting_data } from './painting_data';

function Home() {
    const [columnCount, setColumnCount] = useState(4);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const newColumnCount = Math.floor(screenWidth / 300); // 300px 是每列的最小宽度，可以根据需要调整
            setColumnCount(newColumnCount);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="Paintings" style={{ columnCount: columnCount }}>
            {painting_data.map((painting, index) => (
                <div className="Painting" key={index}>
                    <img src={painting.image_url} alt="painting" />
                </div>
            ))}
        </div>
    );
}

export default Home;
