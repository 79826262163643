export const painting_data = [
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_0239.JPG',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1085.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1086.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1154.JPG',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1157.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1214.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1216.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1423.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_1216.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_20190806_215542.JPG',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/IMG_3252.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%281%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%282%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%283%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%284%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%285%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%285%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%286%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%287%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%288%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%289%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb%20%2810%29.jpeg',
    },
    {
        image_url : 'https://cd-1302933783.cos.ap-guangzhou.myqcloud.com/STEVENPAGE/DRAWINGS/psb.jpeg',
    },

]