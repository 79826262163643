import logo from './logo.svg';
import './Home.css';

function Home() {
  return (
    <div className="Home">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            欢迎来到Steven的网站
        </p>
    </div>
  );
}

export default Home;
